<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer" app clipped>
      <v-list dense>
        <v-list-item-group color="primary">
          <v-list-item v-for="(menu, index) in menus()" :key="index" @click="goto(menu.path)">
            <v-list-item-action>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ menu.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left dark color="primary">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Notifyapp</v-toolbar-title>
      <v-spacer />
      <v-btn text icon @click="logOut()"><v-icon>mdi-power</v-icon></v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-row>
          <v-col>
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-footer app>
      <div class="flex text-center">
        <span class="lead text--secondary text-center" >
          <a href="https://notifyapp.cloud" class="text-decoration-none" target="_blank">Notifyapp.cloud</a>
        </span>
      </div>
    </v-footer>

    <v-dialog
      v-model="dialog"
      width="500px"
      hide-overlay
    >

      <v-card>

        <v-card-title>
      {{ $t('welcome_dialog_title') }}
    </v-card-title>

    <v-card-subtitle>
      {{ $t('welcome_dialog_subtitle') }}
    </v-card-subtitle>
        <v-card-text>
          {{ $t('welcome_dialog_text') }} <b>support@notifyapp.cloud</b>.
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" href="https://workspace.google.com/marketplace/app/notifyapp/256932740718" target="_blank" >
                Google Marketplace
              </v-btn>
          <v-btn color="secondary" @click="dialog = false">{{ $t('close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>

</template>

<script>
import firebase from 'firebase/compat/app';
import { mapActions } from 'vuex';

export default {
  data: () => ({
    dark: false,
    path: '/admin',
    drawer: null,
    dialog: false,
  }),
  created() {
    this.dark = this.$vuetify.theme.dark
    this.loadSMS;
    this.checkFirstTime();
  },
  computed: {
    ...mapActions({
        loadSMS: 'accounts/loadSMS',
        loadAccounts: 'accounts/loadAccounts',
        loadCategories: 'categories/loadCategories',
    }),
  },
  methods: {
    menus() {
      return [
        { path: '', title: this.$t('menu_dashboard'), icon: 'mdi-view-dashboard' },
        { path: '/users', title: this.$t('menu_users'), icon: 'mdi-account-group'},
        { path: '/plans', title: this.$t('menu_plans'), icon: 'mdi-bank-circle-outline' },
        { path: '/sender', title: this.$t('menu_sender'), icon: 'mdi-send'},
        //{ path: '/settings', title: this.$t('menu_settings'), icon: 'mdi-cog' },
        { path: '/addons', title: this.$t('menu_addons'), icon: 'mdi-puzzle' },
        { path: '/support', title: this.$t('menu_support'), icon: 'mdi-help-box' },
      ]
    },
    checkFirstTime(){
      var user = firebase.auth().currentUser;

      var creationTime = user.metadata.creationTime;
      var lastSignInTime = user.metadata.lastSignInTime;
      
      if (creationTime === lastSignInTime) {
        this.dialog = true;
      }
    },
    logOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({path: '/'});
        });
    },
    goto(newPath) {
      this.$router.push({path: this.path + newPath}).catch(()=>{});
    }
  },
  watch: {
    dark: function() {
      this.$vuetify.theme.dark = this.dark;
    }
  }
}
</script>

<style>
#app {
  background: linear-gradient(to left, rgb(255, 255, 255), rgb(250, 250, 250));
}
</style>
